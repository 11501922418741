<template>
    <Header />

    <section style="padding-top: 50px;">

<div class="container">


<div class="row">
    <div class="col-md-2"></div>

<div class="col-md-8">

   <div style="text-align: right;">
        <router-link to="/projects" class="btn p-0"> <i class="bi bi-arrow-left"></i> Go back</router-link>
   </div>

    <div>
    <h4 class="color-1">Research Title : Endometriosis</h4>
</div>

    <img src="/assets/images/research/endom.png" style="width: 100%;" class="shadow p-2">

    <div style="padding-top:3px;">
        <p class="color-1">Photo by by Frank Gaillard/ <a href="https://creativecommons.org/licenses/by/2.0/">CC BY</a></p>
    </div>


    <div>
        <p>

            We are committed to advancing the understanding of endometriosis and working towards improved diagnostics and treatments with our partners and collaborators.
 </p>

        <ul>
            <li>Pathophysiology Investigations: We are actively exploring the intricate mechanisms underlying the development and progression of endometriosis. Our goal is to unravel the complex interplay of genetic, hormonal, and environmental factors contributing to this condition.</li>


            <li>
        Biomarker Discovery: Our Lab is dedicated to identifying reliable biomarkers for the early detection and accurate diagnosis of endometriosis. These biomarkers hold the potential to revolutionize diagnostic approaches and facilitate timely interventions.                
            </li>



            <li>
Patient-Centric Research: Our studies incorporate patient experiences and perspectives, ensuring that our research is not only scientifically rigorous but also addresses the practical challenges faced by those with endometriosis.          

</li>
    
        </ul>

        <p>
            Please do not hesitate to contact us if you would want more information about our work or to discuss possible collaboration. 
        </p>
    </div>


</div>

 <div class="col-md-2"></div>

    
</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from '../layouts/Header'
    import Footer from '../layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>