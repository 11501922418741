<template>
    <Header />

    <section style="padding-top: 150px;">
        

        <div class="container">

            <div class="row">

                <div class="col-md-6" style="background-image: url('/assets/images/lab/lb8.jpg'); background-size: cover; min-height: 300px;"></div>

                <div class="col-md-6" style="background-color: #F6F6F6; padding: 20px;">

                    <div style="padding-top: 40px;">
                        <h1>BCT Research Lab</h1>
                    </div>

                    <div style="padding-bottom: 40px;">
                        <p>
                            Welcome to the Basic Clinical and Translational Research Laboratory’s website. We are a dedicated team committed to advancing our understanding of complex biological systems and their implications for human health.
                        </p>

                        <p>
                        Placenta Research Our team is tirelessly working to understand the complexity of placental function and its implications for the health of the mother and fetus. Our research includes placenta biobanking, understanding the complex mechanisms governing placental functionality in health and diseases, developing innovative screening/diagnostic techniques, and placenta microbiome research.

                        </p>
                    </div>

                </div>




                <div class="col-md-12" style="padding-top: 50px;">
                    
                    <p>
                        Microbiome, Virome, and Metabolome Research We are studying the intricacies of the vaginal and gut microbiomes and their profound impact on human health. Our focus includes microbial dynamics, the influence of these microbiomes on women’s health, bridging the biomes; vaginal and gut microbiomes, and metabolomics.
                    </p>

                    <p>
                        Rhesus Disease Research Our team is making significant strides in understanding the complexities of Rhesus disease, with a focus on advancing both diagnostic and therapeutic approaches. Our research encompasses the epidemiology and mechanisms of Rhesus disease and diagnostic innovations.
                    </p>

                    <p>
                        We welcome collaboration and discussion about our work. Please navigate to our contact page if you would like more information or to discuss potential collaboration opportunities. Thank you for visiting our website.
                    </p>

                </div>

                

            </div>

        </div>
        
    </section>





     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'about',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>


<style>

.partner:hover{
    border: solid 2px #0079A9;
}

</style>