<template>
<Header />
    <section>

        <div>


            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <!--            
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>-->
  <div class="carousel-inner">



    <div class="carousel-item active" data-bs-interval="4000" style="background-image: url('assets/images/lab/lb1.png'); background-size: cover; background-repeat: no-repeat; padding-top: 300px; padding-bottom: 300px; background-position: center;">
      

    </div>







    <div class="carousel-item" data-bs-interval="4000" style="background-image: url('assets/images/lab/lb10.jpg'); background-size: cover; background-repeat: no-repeat; padding-top: 300px; padding-bottom: 300px; background-position: center;">



  </div>






  <div class="carousel-item" data-bs-interval="3000" style="background-image: url('assets/images/lab/lb6.jpg'); background-size: cover; background-repeat: no-repeat; padding-top: 300px; padding-bottom: 300px; background-position: center;">

    

  </div>







  </div>
  <!--
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>-->
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>




           

        </div>

    </section>


    <section data-aos="fade-up" data-aos-duration="2000">

        <div class="container">
            <div class="row">


                <div class="col-md-12" style="text-align: center; padding-top: 60px; padding-bottom: 30px;">

                    <div>
                        <h1>Our Key <span style="color: #0079A9;">Focus</span> Areas </h1>
                    </div>

                </div>

                <div class="col-md-2"></div>

<div class="col-md-8" style="text-align: center;">

<div>
    <p>Obimbo lab is a state-of-the-art facility dedicated to advancing scientific knowledge and discovering breakthroughs in the field of medicine. Equipped with cutting-edge technology and staffed by a team of highly skilled researchers, the lab focuses on investigating various aspects of human health, ranging from understanding the underlying mechanisms of diseases to developing innovative therapies and treatments. </p>
</div>

</div>

<div class="col-md-2"></div>



            </div>

        </div>

    </section>



    <section style="background-color: #F6F6F6; margin-top: 60px; padding-top: 50px; padding-bottom: 50px;">

        <div class="container">

            <div style="padding-bottom: 30px;" data-aos="fade-up" data-aos-duration="2000">
                <h1>Our Research Areas</h1>
            </div>


            <div class="row" data-aos="fade-up" data-aos-duration="2000">

                <div class="col-md-12">

<router-link to="/research/placenta" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | The Placenta</router-link>

<router-link to="/research/microbiome" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Microbiome, Virome, and Metabolome</router-link>


<router-link to="/research/rhesus-disease" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Rhesus Disease</router-link>

<router-link to="/research/endometriosis" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Endometriosis</router-link>

<router-link to="/research/biomarker-discovery" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Biomarker Discovery</router-link>


</div>

                

            </div>

            <div style="padding: 30px; text-align: center; " data-aos="fade-up" data-aos-duration="2000">
                <router-link to="/projects" class="btn theme-btn-1">Load More</router-link>
            </div>

        </div>

    </section>



    <section>

        <div class="container">

            <div style="text-align: center; padding-top: 70px; padding-bottom: 30px;" data-aos="fade-up" data-aos-duration="2000">
                <h1>Facilities & Equipments</h1>
            </div>


            <div class="row">
                
               
                <div class="col-md-4" style="padding: 10px;" data-aos="fade-up" data-aos-duration="2000">
                    <div style="background-image: url('assets/images/lab/DSC_0161.png'); height: 300px; background-size: cover; border-radius: 10px;" class="shadow-lg"></div>
                </div>

                <div class="col-md-4" style="padding: 10px;" data-aos="fade-up" data-aos-duration="2000">
                    <div style="background-image: url('assets/images/lab/lb6.jpg'); height: 300px; background-size: cover; border-radius: 10px;" class="shadow-lg"></div>
                </div>

                <div class="col-md-4" style="padding: 10px;" data-aos="fade-up" data-aos-duration="2000">
                    <div style="background-image: url('assets/images/lab/DSC_0149.png'); height: 300px; background-size: cover; border-radius: 10px;" class="shadow-lg"></div>
                </div>


              

            </div>

        </div>

    </section>





    <section style="background-color: #fff; margin-top: 60px; padding-top: 50px; padding-bottom: 50px;">

<div class="container">

    <div style="padding-bottom: 30px; text-align: center;" data-aos="fade-up" data-aos-duration="2000">
        <h1>Our Publications</h1>
    </div>


    <div class="row" data-aos="fade-up" data-aos-duration="2000">

        <div class="col-md-12">

            <a href="https://doi.org/10.1002/ijgo.13528 
" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Gwako G, Obimbo MM, Gichangi P, Kinuthia J, Gachuno D, Were F (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | Association between obstetric and medical risk factors and Stillbirths in a low-income urban setting. International journal of Gynecology and Obstetrics</a>
        </div>


        <div class="col-md-12">

<a href="https://doi.org/10.1007/s40656-020-00362-7" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Onditi F, Nyadera I, Obimbo MM, Kinyanjui SK (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | How urban 

‘informality’ can inform response to COVID-19: a research agenda for the 

Future. History and Philosophy of Life Sciences 43: 6 (2021)
</a>
</div>


<div class="col-md-12">

<a href="https://doi.org/10.1016/j.tria.2020.100097" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Ongidi I H, Amuti TM, Abdulsalaam FY, Kabare GS, Ouko IP, Otieno EH, 

ButKoigi MK, Koigi PK, Obimbo MM (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Variability in morphology and 

Branching of the internal iliac artery; implications for pelvic surgery Translational Research in Anatomy. 

</a>
</div>



<div class="col-md-12">

<a href="https://doi.org/10.1057/s41287-020-00317-5" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Onditi F, Obimbo MM, Kinyanjui SK, Nyadera I (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Modeling a 

Pandemic (COVID-19) Management Strategy for Urban Slums Using 

Social Geometry Framework?”. Eur J Dev Res (2020)

</a>
</div>


<div class="col-md-12">

<a href="https://doi.org/10.1177/0268355520940453" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Ouko I, Obimbo MM, Kigera J, Ogeng’o JA (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Valve Distribution of 

The Popliteal Vein: A Structural Basis for Deep Venous Thrombosis. 

Phlebology: Journal of venous disease.

</a>
</div>


<div class="col-md-12">

<a href=" https://doi.org/10.11005/jbm.2021.28.1.91" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Sitati F, Obimbo MM, Gichangi P (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | Knowledge and Beliefs on 

Osteoporosis among African postmenopausal women in a Kenyan 

Semirural county of Kiambu. Journal of Bone Metabolism

</a>
</div>

<div class="col-md-12">

<a href="https://doi.org/10.1111/aogs.13956" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Gwako G, Were F, Obimbo MM, Kinuthia J, Gachuno D, Gichangi P 

(2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Association between utilization and quality of antenatal care with 

Stillbirths in four tertiary hospitals in a low-income urban setting. Acta 

Obstetricia et Gynecologica Scandinavica 

</a>
</div>

<div class="col-md-12">

<a href="https://doi.org/10.1521/aeap.2020.32.5.378" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Nutor JJ, Slaughter-Acey JC, Afulani P, Obimbo MM, Mojola S (2020). <br/><i class="bi bi-arrow-right text-primary"></i> |

The Relationship Between Counseling and Adherence to Antiretroviral 

Therapy Among Pregnant and Breastfeeding Women Enrolled in Option B. 

AIDS Educ Prev;32(5):378-391. Doi: 10.1521/aeap.2020.32.5.378

</a>
</div>

    

    </div>

    <div style="padding: 30px; text-align: center; ">
        <router-link to="/publications" class="btn theme-btn-2">Load More</router-link>
    </div>

</div>

</section>



<section data-aos="fade-up" data-aos-duration="2000">

    <div class="container shadow-lg" style="background-color: #0079A9; border-radius: 10px;">

        <div class="row">

            <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px; border-right: solid 1px lightgrey;">
                <h1 style="color: #F19600;"> <i class="bi bi-envelope"></i> </h1>
                <h4 class="text-white">info@obimbolab.africa</h4>
            </div>


            <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px; border-right: solid 1px lightgrey;">
                <h1 style="color: #F19600;"> <i class="bi bi-telephone"></i> </h1>
                <h4 class="text-white">+254 704 910 331</h4>
            </div>


            <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px;">
                <h1 style="color: #F19600;"> <i class="bi bi-globe"></i> </h1>
                <h4 class="text-white">www.obimbolab.africa</h4>
            </div>

        </div>

    </div>

</section>



<section style="background-color: #F6F6F6; margin-top: 70px; padding-bottom: 70px;" data-aos="fade-up" data-aos-duration="2000">

    <div class="container">

        <div style="padding-top: 70px; padding-bottom: 30px;">
            <h1 style="color: #CA7E00;">News & Events</h1>
        </div>

        <div class="row">

            <div class="col-md-4">

<div class="shadow-lg p-1">

    <div style="background-image: url('assets/images/news/prof-2.jpeg'); background-size: cover; height: 300px;"></div>

    <div style="padding-top: 10px;">
        <div style="">
            <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">Breaking new ground on vaginal microbiome impact on pregnancy
</h5>
        </div>
       
        <h6> <i class="bi bi-calendar2-plus"></i> 28.08.2023 | <i class="bi bi-calendar2-plus"></i> 07:00 AM </h6>
        <a href="https://www.standardmedia.co.ke/health-opinion/article/2001480216/breaking-new-ground-on-vaginal-microbiome-impact-on-pregnancy" target="_blank" class="btn theme-btn-1">Read More</a>
    </div> 

</div>    

</div>

            <div class="col-md-4">

<div class="shadow-lg p-1">

    <div style="background-image: url('assets/images/news/who-2.jpeg'); background-size: cover; height: 300px;"></div>

    <div style="padding-top: 10px;">
        <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">New WHO Civil Society Commission to strengthen civil society organizations role in accelerating progress in global health
</h5>
        <h6> <i class="bi bi-calendar2-plus"></i> 24.08.2023 | <i class="bi bi-calendar2-plus"></i> 08:00 AM </h6>
        <a href="https://www.who.int/news/item/24-08-2023-new-who-civil-society-commission-to-strengthen-civil-society-organizations--role-in-accelerating-progress-in-global-health" target="_blank" class="btn theme-btn-1">Read More</a>
    </div> 

</div>    

</div>


<div class="col-md-4">

<div class="shadow-lg p-1">

    <div style="background-image: url('assets/images/news/food.jpeg'); background-size: cover; height: 300px;"></div>

    <div style="padding-top: 10px;">
        <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">WHO launches guide on healthy food at sports events</h5>
        <h6> <i class="bi bi-calendar2-plus"></i> 24.08.2023 | <i class="bi bi-calendar2-plus"></i> 04:00 PM </h6>
        <button class="btn theme-btn-1">Read More</button>
    </div> 

</div>    

</div>







        </div>

    </div>

</section>


<section style="padding-top: 100px;">

<div class="container">

    <div>
        <video style="width: 100%;"  autoplay loop muted playsinline class="shadow-lg">
<source src="/assets/videos/_import_60fa7c3c6130a6.70818159_FPpreview.mp4" type="video/mp4">

Your browser does not support the video tag.
</video>
    </div>

</div>

</section>


<section data-aos="fade-up" data-aos-duration="2000"  style="background-color:#fff; padding-bottom: 80px; padding-top: 80px;">

    <div class="container">

        <div style="text-align: center; padding-top: 50px; padding-bottom: 30px;">
            <h1 class="color-1">Our <span>Team</span></h1>
            <h3 class="color-2"> <i class="bi bi-mouse"></i> </h3>
        </div>

        <div class="row" style="background-color:#F9F9F9; padding-top: 30px; padding-bottom: 20px; border-radius:10px;">

     

            <div class="col-md-4">

                <div style="text-align: center;">

                    <label class="team-image" style="background-image: url('/assets/images/obimbo.png'); background-size: cover;"></label>



                   <div style="padding-top: 10px;">
                        <router-link to="/moses-obimbo" class="btn">Prof. Moses Obimbo </router-link>
                    <h5 class="color-1">Principal Investigator</h5>
                   </div>
                   
                </div>

            </div>




<div class="col-md-4">

<div style="text-align: center;">

     <label class="team-image" style="background-image: url('/assets/images/adoyo.jpg'); background-size: cover; background-position:center;"></label>

    <div style="padding-top: 10px;">
        <router-link to="/david-adoyo" class="btn">David Adoyo </router-link>
    <h5 class="color-1">Research Assistant </h5>
    </div>
   
</div>

</div>





        </div>

    </div>

</section>




<Footer />
</template>

<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
export default {
    name : 'Home',
    components : {Header,Footer},

    created (){
        window.scrollTo(0,0)
    }
}

</script>