<template>
    <Header />

    <section style="padding-top: 150px;">

<div class="container">

    <div>
        <h1>Research Areas</h1>
    </div>
    


<div class="row">

    <div class="col-md-12">

<router-link to="/research/placenta" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | The Placenta</router-link>

<router-link to="/research/microbiome" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Microbiome, Virome, and Metabolome</router-link>


<router-link to="/research/rhesus-disease" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Rhesus Disease</router-link>

<router-link to="/research/endometriosis" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Endometriosis</router-link>

<router-link to="/research/biomarker-discovery" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Biomarker Discovery</router-link>


</div>


 <div class="col-md-12">

<a href="https://gcgh.grandchallenges.org/grant/evaluation-vaginal-microbiome-and-metabolomic-profiles-adverse-pregnancy-outcomes-kenyan" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Evaluation of the Vaginal Microbiome and Metabolomic Profiles in Adverse Pregnancy Outcomes in a Kenyan Population( in progress)</a>

</div>


</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>