<template>
    <Header />

  
<section style="padding-top: 150px;">

<div class="container shadow-lg" style="background-color: #0079A9; border-radius: 10px;">

    <div class="row">

        <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px; border-right: solid 1px lightgrey;">
            <h1 style="color: #F19600;"> <i class="bi bi-envelope"></i> </h1>
            <h4 class="text-white">info@obimbolab.africa</h4>
        </div>


        <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px; border-right: solid 1px lightgrey;">
            <h1 style="color: #F19600;"> <i class="bi bi-phone"></i> </h1>
            <h4 class="text-white">+254 704 910 331</h4>
        </div>


        <div class="col-md-4" style="text-align: center; padding-top: 20px; padding-bottom: 20px;">
            <h1 style="color: #F19600;"> <i class="bi bi-globe"></i> </h1>
            <h4 class="text-white">www.obimbolab.com</h4>
        </div>

    </div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},

        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>