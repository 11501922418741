<template>
    <Header />

    <section style="padding-top: 50px;">

<div class="container">


<div class="row">
    <div class="col-md-2"></div>

<div class="col-md-8">

   <div style="text-align: right;">
        <router-link to="/projects" class="btn p-0"> <i class="bi bi-arrow-left"></i> Go back</router-link>
   </div>

    <div>
    <h4 class="color-1">Research Title : Rhesus Disease</h4>
</div>

    <img src="/assets/images/research/rhesus.png" style="width: 100%;" class="shadow p-2">

    <div style="padding-top:3px;">
        <p class="color-1">Photo by by Konstantina Louka/ <a href="https://creativecommons.org/licenses/by/2.0/">CC BY</a></p>
    </div>


    <div>
        <p>
Our team has been making significant strides in understanding the complexities of this condition, with a focus on advancing both diagnostic and therapeutic approaches. Our research encompasses several key areas:        </p>

        <ul>
            <li>Epidemiology and Mechanisms of Rhesus Disease: We are actively investigating the underlying mechanisms of Rhesus disease, seeking to unravel the molecular and cellular processes that contribute to its development and progression.</li>


            <li>
        Influence on Women's Health: Our research has a strong focus on the impact of these microbiomes on women's health, exploring their role in reproductive health, susceptibility to infections, and overall well-being.                
            </li>



            <li>
Diagnostic Innovations: Our efforts is partnering with other institutions to uptake and develop innovative diagnostic tools for the early detection and monitoring of Rhesus disease. These tools aim to provide more accurate and timely information, allowing for proactive management of affected individuals and especially in maternal emergencies such as postpartum hemorrhage.           

</li>
    
        </ul>

        <p>
            Please do not hesitate to contact us if you would want more information about our work or to discuss possible collaboration. 
        </p>
    </div>


</div>

 <div class="col-md-2"></div>

    
</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from '../layouts/Header'
    import Footer from '../layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>