<template>
    <Header />

  
<section style="padding-top: 150px;">

<div class="container shadow-lg" style="background-color: #F9F9F9;">

    <div class="row">

        <div class="col-md-6" style="background-image: url('/assets/images/obimbo.PNG'); background-size: cover; min-height: 300px;">

        </div>


        <div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">
            <h3 style="color: #0079A9 ;">Prof. Moses M. Obimbo, MD, Ph.D</h3>
            <h5 style="color: #CA7E00;">Principal Investigator</h5>

            <p>
                Prof. Obimbo is physician-scientist with enthusiasm for promotion of learning, research, teaching and clinical practice. He is interested in studying the omics and the pathways they uncover in the field of reproductive biology and he possess excellent skills in information technology, leadership, communication, and team approach to management.
            </p>

            <div>
                <a href="https://www.linkedin.com/in/prof-obimbo-moses-md-ph-d-94622b35/" target="_blank" class="btn theme-btn-2"> <i class="bi bi-linkedin"></i> LinkedIn</a>
            </div>

        </div>

    </div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},

        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>