<template>
    <Header />

  
<section style="padding-top: 150px;">

<div class="container shadow-lg" style="background-color: #F9F9F9;">

    <div class="row">

        <div class="col-md-3" style="background-image: url('/assets/images/rema.jpeg'); background-size: cover; min-height: 300px; background-repeat: no-repeat;">

        </div>


        <div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">
            <h3 style="color: #0079A9 ;">Samwel Rema Gesaka</h3>
            <h5 style="color: #CA7E00;">Research Coodinator</h5>

            <p>
                Rema is a is a graduate student at the Liverpool John Moores University (LJMU) specializing in International Public Health. He holds a Bachelors degree in Medical Laboratory Science and Technology from the University of Nairobi. His research interests include biomarker and utilization of emerging technologies to improve disease diagnosis. Outside class, he enjoys reading, writing, and coding.
            </p>

            <div>
                <a href="https://www.linkedin.com/in/gesaka-rema-58649810a/" target="_blank" class="btn theme-btn-2"> <i class="bi bi-linkedin"></i> LinkedIn</a>
            </div>

        </div>

    </div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},

        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>