import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'

import bootstrap from  'bootstrap/dist/js/bootstrap.js'
import '../public/assets/css/style.css'

import router from './router'
import state from './store'

createApp(App)
.use(bootstrap)
.use(router)
.use(state)
.mount('#app')