<template>
    <Header />

    <section style="padding-top: 50px;">

<div class="container">


<div class="row">
    <div class="col-md-2"></div>

<div class="col-md-8">

   <div style="text-align: right;">
        <router-link to="/projects" class="btn p-0"> <i class="bi bi-arrow-left"></i> Go back</router-link>
   </div>

    <div>
    <h4 class="color-1">Research Title : Biomarker Discovery</h4>
</div>

    <img src="/assets/images/research/Picture 1.png" style="width: 100%;" class="shadow p-3">

    <div style="padding-top:3px;">
        <p class="color-1">Photo by Nicola Segata/ <a href="https://creativecommons.org/licenses/by/3.0/">CC BY</a></p>
    </div>


    <div>
        <p>
            Our Lab is actively engaged in pioneering research focused on the identification and validation of biomarkers across various medical conditions from reproductive health to neuroscience. 
        </p>

        <ul>
            <li>Precision Medicine: Our research is at the forefront of the precision medicine paradigm, aiming to identify biomarkers that enable tailored and more effective treatment strategies for individuals based on their unique biological profiles.</li>
            <li>
Early Disease Detection: We are committed to developing biomarkers that facilitate early detection of diseases, laying the groundwork for timely interventions and improved patient outcomes using least invasive samples such as urine and saliva.            </li>

            <li>
Monitoring Therapeutic Responses: Our investigations extend to monitoring the response to treatments through the identification of biomarkers, allowing for real-time assessment of the effectiveness of interventions.            </li>


        </ul>

        <p>
            If you are interested in learning more about our biomarker discovery initiatives, exploring potential collaborations, or sharing insights from your own work, we would love to connect. 
        </p>
    </div>


</div>

 <div class="col-md-2"></div>

    
</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from '../layouts/Header'
    import Footer from '../layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>