<template>
    <Header />

  
<section style="padding-top: 150px;">

<div class="container shadow-lg" style="background-color: #F9F9F9;">

    <div class="row">

        <div class="col-md-6" style="background-image: url('/assets/images/adoyo.jpg'); background-size: cover; min-height: 300px;">

        </div>


        <div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">
            <h3 style="color: #0079A9 ;">David Adoyo</h3>
            <h5 style="color: #CA7E00;">Research Assistant</h5>

            <p>
                David is a recent graduate with interests in research undertaking his internship at the lab. He has background in placental research and data collection and management. He is interests lie in molecular and translational sciences, data science and analytics. His hobbies include hiking and listening to music
            </p>

            <div>
                <a href="https://www.linkedin.com/in/adoyodavidhenry/" target="_blank" class="btn theme-btn-2"> <i class="bi bi-linkedin"></i> LinkedIn</a>
            </div>

        </div>

    </div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},

        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>