<template>
    <Header />

    <section style="padding-top: 150px;">
        

        <div class="container" data-aos="fade-up" data-aos-duration="2000">

            <div class="row">

                <div class="col-md-6" style="background-color: #F6F6F6; padding: 20px;">

                    <div style="padding-top: 40px;">
                        <h3 style="color:#F19600;">About</h3>
                        <h1>Who We Are</h1>
                    </div>

                    <div style="padding-bottom: 40px;">
                        <p>Obimbo lab is a state-of-the-art facility dedicated to advancing scientific knowledge and discovering breakthroughs in the field of medicine.
                        </p>

                        <p>
                            Equipped with cutting-edge technology and staffed by a team of highly skilled researchers, the lab focuses on investigating various aspects of human health, ranging from understanding the underlying mechanisms of diseases to developing innovative therapies and treatments.

                        </p>
                    </div>

                </div>

                <div class="col-md-6" style="background-image: url('/assets/images/lab/lb8.jpg'); background-size: cover; min-height: 300px;"></div>

            </div>

        </div>
        
    </section>



    <section style="padding-top: 90px;" data-aos="fade-up" data-aos-duration="2000">

        <div class="container">

            <div class="row">

                <div class="col-md-4" style="padding-top: 20px;">
                    <div style="background-color: #0079A9; color: #fff; text-align: center; padding: 10px; border-radius: 10px; " class="shadow-lg">
                        <h3>Our Motto</h3>
                        <p>Unlocking science, uplifting lives: bridging discovery and care</p>
                    </div>
                </div>

                <div class="col-md-4" style="padding-top: 20px;">
                    <div style="background-color: #F19600; color: #fff; text-align: center; padding: 10px; border-radius: 10px; " class="shadow-lg">
                        <h3>Our Vision</h3>
                        <p>Envisioning a Future of Health Beyond Boundaries</p>
                    </div>
                </div>


                <div class="col-md-4" style="padding-top: 20px;">
                    <div style="background-color: #0079A9; color: #fff; text-align: center; padding: 10px; border-radius: 10px; " class="shadow-lg">
                        <h3>Our Mission</h3>
                        <p>Fueled by Purpose, Driven by<br /> Discovery</p>
                    </div>
                </div>

            </div>

        </div>

    </section>



    <section style="padding-top: 90px;" data-aos="fade-up" data-aos-duration="2000">
        

        <div class="container">

            <div class="row">

                <div class="col-md-6" style="background-image: url('/assets/images/lab/lb10.jpg'); background-size: cover; min-height: 300px; background-position: center;"></div>

                <div class="col-md-6" style="background-color: #F6F6F6; padding: 20px;">

                    <div style="padding-top:30px;">
                        <h5>Why Choose Us?</h5>
                    </div>

                    <div style="padding-top: 20px;">
                    <p class="shadow-lg rounded p-3"> <span style="color: #F19600;"> <i class="bi bi-journals"></i> </span> 200+ Publications Done </p>
                    </div>

                    <div>
                    <p class="shadow-lg rounded p-3"> <span style="color: #F19600;"> <i class="bi bi-layers-fill"></i> </span> 150+ Projects Done </p>
                    </div>

                    <div style="padding-bottom: 10px;">
                    <p class="shadow-lg rounded p-3"> <span style="color: #F19600;"> <i class="bi bi-grid"></i> </span> 100+ Resources Done </p>
                    </div>


                    <div style="padding-bottom:40px;">
                        <router-link class="btn theme-btn-1" to="/publications">View publications</router-link>
                    </div>

                </div>

               

            </div>

        </div>
        
    </section>




<section data-aos="fade-up" data-aos-duration="2000"  style="background-color:#fff; padding-bottom: 80px; padding-top: 80px;">

    <div class="container">

        <div style="text-align: center; padding-top: 50px; padding-bottom: 30px;">
            <h1 class="color-1">Our <span>Team</span></h1>
            <h3 class="color-2"> <i class="bi bi-mouse"></i> </h3>
        </div>

        <div class="row" style="background-color:#F9F9F9; padding-top: 30px; padding-bottom: 20px; border-radius:10px;">

     

            <div class="col-md-4">

                <div style="text-align: center;">

                    <label class="team-image" style="background-image: url('/assets/images/obimbo.png'); background-size: cover;"></label>
                    


                   <div style="padding-top: 10px;">
                        <router-link to="/moses-obimbo" class="btn">Prof. Moses Obimbo </router-link>
                    <h5 class="color-1">Principal Investigator</h5>
                   </div>
                   
                </div>

            </div>


           


<div class="col-md-4">

<div style="text-align: center;">

     <label class="team-image" style="background-image: url('/assets/images/adoyo.jpg'); background-size: cover; background-position:center;"></label>

    <div style="padding-top: 10px;">
        <router-link to="/david-adoyo" class="btn">David Adoyo </router-link>
    <h5 class="color-1">Research Assistant </h5>
    </div>
   
</div>

</div>





        </div>

    </div>

</section>







     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'about',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>


<style>

.partner:hover{
    border: solid 2px #0079A9;
}

</style>