<template>
    <Header />

    <section style="padding-top: 150px;">

        <div class="container">

            <div>
                <h1>Research Publications</h1>
            </div>



    <div class="row">

<div class="col-md-12">

    <a href="https://doi.org/10.1002/ijgo.13528 
" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Gwako G, Obimbo MM, Gichangi P, Kinuthia J, Gachuno D, Were F (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | Association between obstetric and medical risk factors and Stillbirths in a low-income urban setting. International journal of Gynecology and Obstetrics</a>
</div>


<div class="col-md-12">

<a href="https://doi.org/10.1007/s40656-020-00362-7" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Onditi F, Nyadera I, Obimbo MM, Kinyanjui SK (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | How urban 

‘informality’ can inform response to COVID-19: a research agenda for the 

Future. History and Philosophy of Life Sciences 43: 6 (2021)
</a>
</div>


<div class="col-md-12">

<a href="https://doi.org/10.1016/j.tria.2020.100097" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Ongidi I H, Amuti TM, Abdulsalaam FY, Kabare GS, Ouko IP, Otieno EH, 

ButKoigi MK, Koigi PK, Obimbo MM (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Variability in morphology and 

Branching of the internal iliac artery; implications for pelvic surgery Translational Research in Anatomy. 

</a>
</div>



<div class="col-md-12">

<a href="https://doi.org/10.1057/s41287-020-00317-5" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Onditi F, Obimbo MM, Kinyanjui SK, Nyadera I (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Modeling a 

Pandemic (COVID-19) Management Strategy for Urban Slums Using 

Social Geometry Framework?”. Eur J Dev Res (2020)

</a>
</div>


<div class="col-md-12">

<a href="https://doi.org/10.1177/0268355520940453" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Ouko I, Obimbo MM, Kigera J, Ogeng’o JA (2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Valve Distribution of 

The Popliteal Vein: A Structural Basis for Deep Venous Thrombosis. 

Phlebology: Journal of venous disease.

</a>
</div>


<div class="col-md-12">

<a href=" https://doi.org/10.11005/jbm.2021.28.1.91" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Sitati F, Obimbo MM, Gichangi P (2021). <br/><i class="bi bi-arrow-right text-primary"></i> | Knowledge and Beliefs on 

Osteoporosis among African postmenopausal women in a Kenyan 

Semirural county of Kiambu. Journal of Bone Metabolism

</a>
</div>

<div class="col-md-12">

<a href="https://doi.org/10.1111/aogs.13956" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Gwako G, Were F, Obimbo MM, Kinuthia J, Gachuno D, Gichangi P 

(2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Association between utilization and quality of antenatal care with 

Stillbirths in four tertiary hospitals in a low-income urban setting. Acta 

Obstetricia et Gynecologica Scandinavica 

</a>
</div>

<div class="col-md-12">

<a href="https://doi.org/10.1521/aeap.2020.32.5.378" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Nutor JJ, Slaughter-Acey JC, Afulani P, Obimbo MM, Mojola S (2020). <br/><i class="bi bi-arrow-right text-primary"></i> |

The Relationship Between Counseling and Adherence to Antiretroviral 

Therapy Among Pregnant and Breastfeeding Women Enrolled in Option B. 

AIDS Educ Prev;32(5):378-391. Doi: 10.1521/aeap.2020.32.5.378

</a>
</div>





<div class="col-md-12">

<a href="hhttps://doi.org/10.4314/aja.v9i2.199946" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Ongidi I, Abdulsalaam F, Otieno H, Odero N,Pulei A, Obimbo MM, Ongeti 

K (2020)
 <br/><i class="bi bi-arrow-right text-primary"></i> |

 A review of teleocytes in cardiovascular tissue and their role in 

Angiogenesis. Anatomy Journal of Africa, 9(2): 1807 – 1815


</a>
</div>



<div class="col-md-12">

<a href=" https://doi.org/10.1016/j.tria.2020.100075" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Misiani M, Amuti T, Darbar S, Mandela P, Maranga E, Obimbo MM

(2020). <br/><i class="bi bi-arrow-right text-primary"></i> | Sex determination from dimensions of distal tibiae in adult 

Kenyans: A discriminant function analysis. Translational Research in 

Anatomy, 20:10075


</a>
</div>


<div class="col-md-12">

<a href="https://glowm.cms12.ama.uk.com/article-preview/id/409573#.XL-5LqeZPyI
" target="_blank" class="btn research shadow-lg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> <i class="bi bi-check-circle text-primary"></i> | Obimbo MM, Stephanie Gaw (2019). <br/><i class="bi bi-arrow-right text-primary"></i> | Preterm labor (Suspected and 

Confirmed). The Global Library of Womens’ Medicine (GLOWM), The 

International federation of Gynecology and Obstetrics (FIGO). 


</a>
</div>








</div>
            


    

        </div>

    </section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>