<template>
    <Header />

  
    <section style="padding-top: 150px;">

<div class="container">

    <div>
        <h1>Resources</h1>
    </div>
    


<div class="row">

<div class="col-md-12">

<p>None available at the moment</p>


</div>



</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>