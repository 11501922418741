<template>

<footer style="padding-top: 100px;">

<div style="background-color: #1a1a1a; padding-top: 100px; padding-bottom: 40px;">

    <div class="container">

    <div class="row">

        <div class="col-md-3">
            <div style="padding-bottom: 20px;"> <img src="/assets/images/logo-3.png" style="height: 30px; "> </div>
           <p class="text-white">Advancing Healthcare through Cutting-Edge Research, Pioneering Science, Transforming Lives</p>
        </div>

        <div class="col-md-3 col-6">
            <router-link to="/" class="btn text-white">Home</router-link><br>
            <router-link to="/about" class="btn text-white">About Us</router-link><br>
            <router-link to="/contact" class="btn text-white">Contact Us</router-link> <br>
            <router-link to="/news" class="btn text-white">News & Events</router-link> <br>
            
        </div>


        <div class="col-md-3 col-6">
            <router-link to="/publications" class="btn text-white">Publications</router-link><br>
            <router-link to="/projects" class="btn text-white">Research Projects</router-link><br>
            <router-link to="/resources" class="btn text-white">Resources</router-link> <br>
           
        </div>

        <div class="col-md-3">
            <p style="color: lightgrey;">Social Media</p>

            <div>

                <a style="text-decoration: none; font-size: 30px; margin-right: 10px; color: #0079A9;" href="https://www.linkedin.com/in/obimbo-lab-900a11298/
" target="_blank"> <i class="bi bi-linkedin"></i> </a>

                <a style="text-decoration: none; font-size: 30px; margin-right: 10px; color: #0079A9;" href="https://web.facebook.com/profile.php?id=61552895072527
" target="_blank"> <i class="bi bi-facebook"></i> </a>


                <a style="text-decoration: none; font-size: 30px; margin-right: 10px; color: #0079A9;" href="https://www.instagram.com/obimbolab/
" target="_blank"> <i class="bi bi-instagram"></i> </a>
                <a style="text-decoration: none; font-size: 30px; color: #0079A9;" href="https://twitter.com/Obimbolab
" target="_blank"> <i class="bi bi-twitter"></i> </a>


            </div>

        </div>

    </div>

</div>
    
</div>

<div style="background-color: #000; text-align: center; padding-top: 20px; padding-bottom: 20px; ">
    <p style="color: lightgrey;">Copyright | Obimbo Lab | 2024</p>
</div>

</footer>

</template>