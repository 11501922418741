<template>
    <Header />

  
<section style="background-color: #F6F6F6; margin-top: 70px; padding-bottom: 70px;" data-aos="fade-up" data-aos-duration="2000">

<div class="container">

    <div style="padding-top: 70px; padding-bottom: 30px;">
        <h1 style="color: #CA7E00;">News & Events</h1>
    </div>

    <div class="row">



        <div class="col-md-4">

<div class="shadow-lg p-1">

    <div style="background-image: url('assets/images/news/prof-2.jpeg'); background-size: cover; height: 300px;"></div>

    <div style="padding-top: 10px;">
        <div style="">
            <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">Breaking new ground on vaginal microbiome impact on pregnancy
</h5>
        </div>
       
        <h6> <i class="bi bi-calendar2-plus"></i> 28.08.2023 | <i class="bi bi-calendar2-plus"></i> 07:00 AM </h6>
        <a href="https://www.standardmedia.co.ke/health-opinion/article/2001480216/breaking-new-ground-on-vaginal-microbiome-impact-on-pregnancy" target="_blank" class="btn theme-btn-1">Read More</a>
    </div> 

</div>    

</div>




        <div class="col-md-4">

            <div class="shadow-lg p-1">

                <div style="background-image: url('assets/images/news/who-1.jpeg'); background-size: cover; height: 300px;"></div>

                <div style="padding-top: 10px;">
                    <div style="">
                        <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">Launch of the WHO Online Repository of Evidence-Informed Decision-Making (EIDM) Tools</h5>
                    </div>
                   
                    <h6> <i class="bi bi-calendar2-plus"></i> 24.08.2023 | <i class="bi bi-calendar2-plus"></i> 07:00 AM </h6>
                    <a href="https://www.who.int/news/item/24-08-2023-launch-of-the-who-online-repository-of--evidence-informed-decision-making-(eidm)-tools" target="_blank" class="btn theme-btn-1">Read More</a>
                </div> 

            </div>    

        </div>

        <div class="col-md-4">

<div class="shadow-lg p-1">

<div style="background-image: url('assets/images/news/who-2.jpeg'); background-size: cover; height: 300px;"></div>

<div style="padding-top: 10px;">
    <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">New WHO Civil Society Commission to strengthen civil society organizations role in accelerating progress in global health
</h5>
    <h6> <i class="bi bi-calendar2-plus"></i> 24.08.2023 | <i class="bi bi-calendar2-plus"></i> 08:00 AM </h6>
    <a href="https://www.who.int/news/item/24-08-2023-new-who-civil-society-commission-to-strengthen-civil-society-organizations--role-in-accelerating-progress-in-global-health" target="_blank" class="btn theme-btn-1">Read More</a>
</div> 

</div>    

</div>


<div class="col-md-4">

<div class="shadow-lg p-1">

<div style="background-image: url('assets/images/news/food.jpeg'); background-size: cover; height: 300px;"></div>

<div style="padding-top: 10px;">
    <h5 style="color: #0079A9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;">WHO launches guide on healthy food at sports events</h5>
    <h6> <i class="bi bi-calendar2-plus"></i> 24.08.2023 | <i class="bi bi-calendar2-plus"></i> 04:00 PM </h6>
    <button class="btn theme-btn-1">Read More</button>
</div> 

</div>    

</div>







    </div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from './layouts/Header'
    import Footer from './layouts/Footer'
    export default {
        name : 'publications',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>