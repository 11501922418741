<template>

<nav class="navbar fixed-to header-style navbar-expand-lg navbar-light bg-light shadow-lg">
  <div class="container-fluid">
    <router-link style="font-size: 30px;" to="/" class="navbar-brand"> <img src="/assets/images/logo-2.png" style="height: 30px;"> </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page" >Home</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/about" class="nav-link active" aria-current="page">About Us</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/publications" class="nav-link active" aria-current="page">Publications</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/projects" class="nav-link active" aria-current="page">Research Areas</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/bct-research-lab" class="nav-link active" aria-current="page">BCT Research Lab</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/resources" class="nav-link active" aria-current="page" >Resources</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/news" class="nav-link active" aria-current="page" >News & Events</router-link>
        </li>

    
        
      </ul>
      <div class="d-flex">
        <router-link to="/contact" class="btn theme-btn-1">Contact Us</router-link>
      </div>
    </div>
  </div>
</nav>

</template>