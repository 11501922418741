<template>
    <Header />

    <section style="padding-top: 50px;">

<div class="container">


<div class="row">
    <div class="col-md-2"></div>

<div class="col-md-8">

   <div style="text-align: right;">
        <router-link to="/projects" class="btn p-0"> <i class="bi bi-arrow-left"></i> Go back</router-link>
   </div>

    <div>
    <h4 class="color-1">Research Title : Placenta</h4>
</div>

    <img src="/assets/images/research/placenta.png" style="width: 100%;">

    <div style="padding-top:3px;">
        <p class="color-1">Photo by Moses Obimbo/ <a href="https://creativecommons.org/licenses/by/4.0/">CC BY</a></p>
    </div>


    <div>
        <p>
            To understand the complexity of placental function and its implications for the health of the mother and fetus, our committed team has been working nonstop. Several facets of placental biology have been the subject of our research, including but not limited to:
        </p>

        <ul>
            <li>Placenta biobanking: Placentas have been collected and preserved as frozen sections or embedded in paraffin. We have placenta from various disease phenotypes as well as healthy pregnancies.</li>
            <li>
                The understanding of the complex mechanisms governing placental functionality in health and diseases, including HIV, preeclampsia, preterm birth, PPROM, stillbirth, diabetes, and malnutrition, has advanced significantly. This has provided insight into how placental functionality affects the general health of the mother and the developing fetus.
                
            </li>

            <li>
                Innovative Screening/Diagnostic techniques: To evaluate placental health throughout pregnancy, we are currently working on novel discoveries. With the help of these technologies, preventive management of possible complications such as stillbirth, premature birth, and preeclampsia will be possible. The tools are designed to deliver more precise information.
            </li>
            <li>
                Placenta microbiome research: Expanding on our basic studies, we are investigating the placenta to identify its microbial populations. Our objective is to advance the body of information that can benefit expectant mothers and their babies.
            </li>
        </ul>

        <p>
            Please do not hesitate to contact us if you would want more information about our work or to discuss possible collaboration. 
        </p>
    </div>


</div>

 <div class="col-md-2"></div>

    
</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from '../layouts/Header'
    import Footer from '../layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>