<template>
    <Header />

    <section style="padding-top: 50px;">

<div class="container">


<div class="row">
    <div class="col-md-2"></div>

<div class="col-md-8">

   <div style="text-align: right;">
        <router-link to="/projects" class="btn p-0"> <i class="bi bi-arrow-left"></i> Go back</router-link>
   </div>

    <div>
    <h4 class="color-1">Research Title : Microbiome, Virome, and Metabolome</h4>
</div>

    <img src="/assets/images/research/microbiome.png" style="width: 100%;" class="shadow">

    <div style="padding-top:3px;">
        <p class="color-1">Photo by Amie Foster/ <a href="https://creativecommons.org/licenses/by/3.0/">CC BY</a></p>
    </div>


    <div>
        <p>
            We have been studying the intricacies of the vaginal and gut and their profound impact on human health. The focus has been on:
        </p>

        <ul>
            <li>Microbial Dynamics: We are actively investigating the dynamic composition of both the vaginal and gut microbiomes, seeking to unravel the factors that influence their diversity and stability over time and especially in pregnancy and infants’ life.</li>
            <li>
        Influence on Women's Health: Our research has a strong focus on the impact of these microbiomes on women's health, exploring their role in reproductive health, susceptibility to infections, and overall well-being.                
            </li>

            <li>
Bridging the Biomes; Vaginal and Gut Microbiomes: Understanding the intricate interplay between these two microbiomes is a key aspect of our work. We are exploring how changes in one may influence the other and subsequently affect the overall health of individuals and pregnancy outcomes.            </li>
            <li>
                Metabolomics: Our investigations extend to the metabolomics of variations in the vaginal and gut microbiomes. We intend to use this information to develop diagnostic and therapeutic targets in association with conditions such as reproductive disorders, gastrointestinal diseases, and immune system function.
            </li>
        </ul>

        <p>
            Please do not hesitate to contact us if you would want more information about our work or to discuss possible collaboration. 
        </p>
    </div>


</div>

 <div class="col-md-2"></div>

    
</div>

</div>

</section>
     
    <Footer />
    </template>
    
    <script>
    import Header from '../layouts/Header'
    import Footer from '../layouts/Footer'
    export default {
        name : 'projects',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    
    </script>