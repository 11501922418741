import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'
import Publications from '../components/Publications'
import Projects from '../components/Projects'
import Resources from '../components/Resources'
import News from '../components/News'
import Contact from '../components/Contact'
import Obimbo from '../components/Obimbo'
import Rema from '../components/Rema'
import David from '../components/David'
import Placenta from '../components/research/Placenta'
import Microbiome from '../components/research/Microbiome'
import RhesusDisease from '../components/research/RhesusDisease'
import Endometriosis from '../components/research/Endometriosis'
import BiomarkerDiscovery from '../components/research/BiomarkerDiscovery'
import BctResearchLab from '../components/BctResearchLab'




const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },

  {
    path: '/publications',
    name: 'publications',
    component: Publications
  },

  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },

  {
    path: '/resources',
    name: 'resources',
    component: Resources
  },

  {
    path: '/news',
    name: 'news',
    component: News
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },

  {
    path: '/moses-obimbo',
    name: 'obimbo',
    component: Obimbo
  },

  {
    path: '/rema-gesaka',
    name: 'rema',
    component: Rema
  },


  {
    path: '/david-adoyo',
    name: 'david',
    component: David
  },


   {
    path: '/research/placenta',
    name: 'placenta',
    component: Placenta
  },

     {
    path: '/research/microbiome',
    name: 'microbiome',
    component: Microbiome
  },

    {
    path: '/research/rhesus-disease',
    name: 'rhesus-disease',
    component: RhesusDisease
  },


     {
    path: '/research/endometriosis',
    name: 'endometriosis',
    component: Endometriosis
  },



     {
    path: '/research/biomarker-discovery',
    name: 'biomarker-discovery',
    component: BiomarkerDiscovery
  },



     {
    path: '/bct-research-lab',
    name: 'bct-research-lab',
    component: BctResearchLab
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
